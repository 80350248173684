(() => {
  const handleHeader = () => {
    let $header: HTMLElement,
      $mainMenuEls: NodeListOf<HTMLElement>,
      showSubmenuClass = 'show-submenu';

    const initVariables = () => {
      $header = document.querySelector('.gen-header') as HTMLElement;
      $mainMenuEls = $header?.querySelectorAll(
        '.emu-navigation__content-wrapper nav li.emu-navigation__item'
      );
    };

    const appendEvents = () => {
      // when menu element is clicked, add a class to the menu item, to show the submenu
      $mainMenuEls.forEach($el => {
        $el.addEventListener('click', () => {
          if ($el.classList.contains(showSubmenuClass)) {
            $el.classList.remove(showSubmenuClass);
          } else {
            // removing show-submenu class from any other element
            $mainMenuEls.forEach($mainMenuEl => {
              $mainMenuEl.classList.remove(showSubmenuClass);
            });
            // adding show submenu class
            $el.classList.add(showSubmenuClass);
          }
        });
      });

      // when page is scrolled, add a class to header, to add a background color
      window.addEventListener('scroll', () => {
        $header?.classList.toggle('gen-header--background', window.scrollY > 0);
      });
    };

    initVariables();
    appendEvents();
  };

  //to handle the scroll in header menu
  const handleMenuScroll = () => {
    let $headerContainer: HTMLElement,
      $headerToggler: HTMLButtonElement,
      anchorsWithHash: HTMLAnchorElement[],
      scrollId: string,
      targetElement;

    const initVariables = () => {
      $headerContainer = document.querySelector(
        '.gen-header__nav-container'
      ) as HTMLElement;
      $headerToggler = document.querySelector(
        '.gen-header__toggler'
      ) as HTMLButtonElement;
    };

    const appendEvent = () => {
      anchorsWithHash = Array.from(
        $headerContainer.querySelectorAll('a')
      ).filter(anchor => anchor.getAttribute('href')?.includes('#'));

      anchorsWithHash.forEach(anchor => {
        anchor.addEventListener('click', e => {
          scrollId = anchor.getAttribute('href')?.split('#')[1] as string;
          targetElement = document.getElementById(scrollId);

          if (targetElement) {
            e.preventDefault();
            $headerToggler.click();
            targetElement.scrollIntoView({
              behavior: 'smooth',
            });
          }
        });
      });

      $headerToggler.addEventListener('click', () => {
        if ($headerContainer.classList.contains('js-toggle-on')) {
          document.body.classList.add('mobile-menu-opened');
        } else {
          document.body.classList.remove('mobile-menu-opened');
        }
      });
    };

    initVariables();
    appendEvent();
  };

  const init = () => {
    handleHeader();
    handleMenuScroll();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
