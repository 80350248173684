import utils from '../../utils';
(() => {
  /**  To refill the values into the form once it gets reset from AAAEM
      Iterating through the form, based on form elements refilling the values */
  const fillFormValues = ($form, formValues) => {
    if (!$form) {
      return;
    }

    const namedFormElements = $form.querySelectorAll('[name]');

    namedFormElements.forEach(element => {
      const elementName = element.getAttribute('name');

      if (!elementName) return;

      const matchingPair = formValues.find(([name, _]) => name === elementName);

      if (!matchingPair) return;

      const [_, value] = matchingPair;

      if (element instanceof HTMLSelectElement) {
        element.value = value;
        toggleTextColor(element);

        if (
          elementName.toLowerCase() === 'speciality' &&
          value.toLowerCase() === 'other'
        ) {
          const otherField = $form.querySelector('.gen-form__form-other-field');
          if (otherField) {
            utils.toggleFieldRequired(otherField, true);
          }
        }
      } else if (element instanceof HTMLInputElement) {
        if (element.type === 'checkbox' || element.type === 'radio') {
          element.checked = value === 'true' || value === element.value;
          const fieldSet =
            element.type === 'radio'
              ? element.closest('.emu-form-radio')
              : element.closest('.emu-form-checkbox');

          // @ts-ignore
          fieldSet?._update();
        } else {
          element.value = value;
        }
      } else if (element instanceof HTMLTextAreaElement) {
        element.value = value;
      }

      // @ts-ignore
      element?._update?.();
    });
  };

  /** On submit button click, need to store the form values
  These values should retain in form if the form fails to submit
    handleFormSuccessEvent function handles the success and error message of form
  */
  const getFormDataForRefilling = ($form: HTMLElement) => {
    let $submitButton: HTMLElement;

    $submitButton = $form.querySelector(
      '.gen-form__form-submit'
    ) as HTMLElement;
    const formId = $form.id;
    let formValues;

    try {
      window.Bus.on(`emu-form-${formId}:submitted`, response => {
        let responseData = response.responseData;
        const jsonObject = JSON.parse(JSON.parse(responseData)); // intentionally parsing twice to accommodate the response structure
        if (jsonObject?.status?.toLowerCase() === 'error') {
          // fill the form with values
          fillFormValues($form, formValues);
        }
      });

      $submitButton.addEventListener('click', () => {
        const formData = new FormData($form as HTMLFormElement);
        const entries = Array.from(formData.entries()).filter(
          ([key]) =>
            key !== 'g-recaptcha-response' && key !== 'recaptchaVerification'
        );

        // if checkbox is not checked formData doesn't include it, to handle that including false value in it when unchecked
        const $checkboxList = $form.querySelectorAll(
          'input[type="checkbox"]'
        ) as NodeListOf<HTMLInputElement>;
        $checkboxList.forEach(checkbox => {
          if (!formData.has(checkbox.name)) {
            entries.push([checkbox.name, 'false']);
          }
        });
        formValues = entries;
      });
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * to handle analytics of form
   * adds listeners on form fields to check if form has started or not
   */
  const handleFormTouchedAnalyticsEvent = ($form: HTMLElement) => {
    let $formFields: NodeListOf<HTMLElement>;
    let metaData, $metaContainer, eventDetail;

    $formFields = $form.querySelectorAll('input, select, textarea');
    $metaContainer = $form.closest('.gen-form__form-container');
    metaData = $metaContainer?.getAttribute('data-meta');

    if (metaData) {
      try {
        eventDetail = {
          meta: JSON.parse(metaData),
        };
      } catch (e) {
        console.warn(e);
      }

      if (eventDetail.meta) {
        const addListeners = () => {
          $formFields.forEach(field => {
            if (field.tagName.toLowerCase() === 'select') {
              field.addEventListener('change', handleChange);
            } else {
              field.addEventListener('input', handleChange);
            }
          });
        };

        const removeListeners = () => {
          $formFields.forEach(field => {
            if (field.tagName.toLowerCase() === 'select') {
              field.removeEventListener('change', handleChange);
            } else {
              field.removeEventListener('input', handleChange);
            }
          });
        };

        const handleChange = () => {
          window.Bus.emit(`emu-form-${$form.id}:started`, eventDetail);
          removeListeners();
        };

        addListeners();
      }
    }
  };

  // appends a submit event to handle
  // 1. scrolling to the first error if there is an error on the page
  // 2. Adding a class to the form to show/hide the loader
  const handleFormSubmitClick = ($form: HTMLElement) => {
    const formId = $form.id;
    $form.addEventListener('submit', () => {
      let $errorField: HTMLElement;
      const $errorFields = Array.from(
        $form.querySelectorAll('.js-has-error')
      ) as HTMLElement[];

      let hasErrors = false;

      if ($errorFields?.length) {
        // getting error field which is not a hidden field
        $errorField = $errorFields.find(
          field => !field.closest('.js-hide-field')
        ) as HTMLElement;

        // scrolling to the first error of the page
        if ($errorField) {
          const headerHeight = 101; // height of the header
          const scrollPosition =
            $errorField.getBoundingClientRect().top +
            window.scrollY -
            headerHeight; // to adjust the position of scroll as header hides the field

          window.scrollTo({
            top: scrollPosition,
            behavior: 'smooth',
          });
          hasErrors = true;
        }
      }

      if (hasErrors === false) {
        $form?.classList.add('emu-form--loading');
      }
    });

    window.Bus.on(`emu-form-${formId}:submitted`, () => {
      $form?.classList.remove('emu-form--loading');
    });
  };

  //to update select button placeholder text color
  const toggleTextColor = selectElement => {
    let selectButton = selectElement
      .closest('.container')
      ?.querySelector('button [data-ref="button-text"]');

    if (selectButton) {
      selectButton.classList.toggle(
        'u-text-color--grey',
        selectElement.value === '--'
      );
    }
  };

  /** appends a submitted event as form API gives success and error messages  
        On Success/Warning message, it shows content from API
        On Error, it shows content from API and values should retain in form. 
            fillFormValues() function added to override AAAEM common form reset */
  const handleFormSuccessEvent = ($form: HTMLElement) => {
    let formId: string;
    let $successElement: HTMLElement;
    let $errorElement: HTMLElement;
    let $apiResponseElement: HTMLElement;
    let messagingTime = 10000;

    const initVariables = () => {
      $successElement = $form.querySelector(
        '.emu-form__message--success'
      ) as HTMLElement;
      $errorElement = $form.querySelector(
        '.emu-form__message--error'
      ) as HTMLElement;
      $apiResponseElement = $form.querySelector(
        '.emu-form__message--api-response'
      ) as HTMLElement;
      formId = $form.id;
    };

    const appendEvents = () => {
      window.Bus.on(`emu-form-${formId}:submitted`, response => {
        try {
          let responseData = response.responseData;
          const jsonObject = JSON.parse(JSON.parse(responseData)); // intentionally parsing twice to accommodate the response structure

          if (jsonObject?.status?.toLowerCase() === 'error') {
            $errorElement?.classList.remove('u-hide');
            let errorMessages = jsonObject?.errorMessage;

            if (errorMessages && errorMessages.length > 0) {
              $apiResponseElement.innerHTML = '';

              errorMessages.forEach(error => {
                $apiResponseElement.innerHTML += `<p>${error.message}</p>`;
              });

              $apiResponseElement?.classList.remove('u-hide');
            }
          } else {
            $successElement?.classList.remove('u-hide');
          }

          // making sure that the messages gets hidden after 10 seconds
          setTimeout(() => {
            $successElement?.classList.add('u-hide');
            $apiResponseElement?.classList.add('u-hide');
            $errorElement?.classList.add('u-hide');
          }, messagingTime);

          // to reset recaptcha after the form submitted
          try {
            if (window.grecaptcha) {
              window.grecaptcha.reset();
            }
          } catch (recaptchaError) {
            console.warn('Recaptcha reset failed:', recaptchaError);
          }
        } catch (e) {
          console.warn(e);
        }
      });
    };

    initVariables();
    appendEvents();
  };

  // to handle customized select placeholder
  const handleSelectPlaceholder = () => {
    let selectElements: NodeListOf<HTMLSelectElement>;

    const updateButtonState = () => {
      selectElements = document.querySelectorAll(
        'select'
      ) as NodeListOf<HTMLSelectElement>;

      selectElements.forEach(select => {
        select.addEventListener('change', e => {
          toggleTextColor(e.target);
        });

        toggleTextColor(select);
      });
    };

    updateButtonState();
  };

  /**
   * validates all date fields with gen-form__date-field class.
   * Adds an error to the form field for the following cases
   *    if the input does not have a valid value (like 02/31/2025)
   * Along with these if a class gen-form__date-field--disallow-future-dates, adds an error if date input has future date as a value
   */
  const validateDateFields = () => {
    const $dateFields = document.querySelectorAll(
      '.gen-form__date-field'
    ) as NodeListOf<HTMLElement>;
    $dateFields.forEach($el => {
      const $input = $el.querySelector(
        '.emu-form-text__input'
      ) as HTMLInputElement;
      $input.addEventListener(
        'keyup',
        utils.debounce(() => {
          if ($el.classList.contains('js-validation-failed') !== true) {
            const val = $input.value;
            // checking for validity of the date
            const dateIsValid = utils.isValidDate(val);
            utils.toggleErrorOnFormField($el, dateIsValid ? false : true);

            // if the date is valid, and if the form has gen-form__date-field--disallow-future-dates class, adding error if the date value is from future
            if (
              dateIsValid &&
              $el.classList.contains(
                'gen-form__date-field--disallow-future-dates'
              )
            ) {
              const isDateFromFuture = utils.isFutureDate(val);
              utils.toggleErrorOnFormField($el, isDateFromFuture);
            }
          }
        })
      );
    });
  };

  const init = () => {
    let $formEls = document.querySelectorAll(
      '.emu-form'
    ) as NodeListOf<HTMLElement>;

    $formEls.forEach($el => {
      getFormDataForRefilling($el);
      handleFormTouchedAnalyticsEvent($el);
      handleFormSubmitClick($el);
      handleFormSuccessEvent($el);
    });

    validateDateFields();
    handleSelectPlaceholder();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
