// Stylesheets
import './main.scss';

import './resources/images/favicon.png';

// Imports all component based scss files.
import './components/**/*.scss';

// Imports all component based ts files.
import './components/**/*.ts';

// importing utilities
import utils from './utils';

// import favicon here.

interface AAAEM {
  isMobile: boolean;
  isTouch: boolean;
  isAuthorMode: boolean;
  env: string;
  browser: {
    name: string;
    version: string;
  };
  // All utility functions can be found here: https://gitlab.com/venture-moonwalker/aaaem/aaaem-common/-/blob/645c2f4310c2cf0864454035850a95d28e65e68e/ui.frontend/src/global.ts
  utilities: any;
  plugins: {
    tinySlider: any;
    badgerAccordion: any;
    tabbyJs: any;
    plyr: any;
  };
}

declare global {
  interface Window {
    AAAEM: AAAEM;
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed
    _badgerInstances: any; //accordion
    grecaptcha: any;
  }
}

(() => {
  // when speciality field dropdown is selected, if the value of it is "other", an additional field must be shown and it should be made required
  // speciality field is present in rising star and start page forms. Same functionality on both of these
  const handleSpecialityField = () => {
    const $specialityField = document.querySelector(
      '.gen-form__form-speciality-field .emu-form-dropdown__select'
    ) as HTMLSelectElement;
    const $otherField = document.querySelector(
      '.gen-form__form-other-field .emu-form-text__input'
    ) as HTMLElement;

    if ($specialityField && $otherField) {
      utils.hideElOnSelect($specialityField, 'other', $otherField, true);
    }
  };

  // executes code very specific to the "rising star" form
  const handleRisingStarForm = () => {
    handleSpecialityField();

    // for the graduation field, if there are no errors in the field (validated by validateDateFields method)
    // if date entered is more than 2 years ago, show additional field
    // if date entered is less than or equal to 2 years ago, hide the additional field
    const $gradDateWrapper = document?.querySelector(
      '.rising-star__enroll-form-graduation-field'
    );
    if ($gradDateWrapper) {
      const $conditionalField = document?.querySelector(
        '.rising-star__enroll-form-conditional-field'
      );
      const $gradField = $gradDateWrapper.querySelector(
        '.emu-form-text__input'
      ) as HTMLInputElement;
      const twoYearsAgoDate = utils.getDateFromYearsAgo(2);
      const hideConditionalField = () => {
        utils.toggleFieldRequired($conditionalField, false);
      };
      const showConditionalField = () => {
        utils.toggleFieldRequired($conditionalField, true);
      };
      // 500 in the debounce timer is intentional, as validateDateFields is using a 300 and this callback has to be executed only after validateDateFields is done
      $gradField?.addEventListener(
        'keyup',
        utils.debounce(() => {
          if ($gradDateWrapper.classList.contains('js-has-error')) {
            hideConditionalField();
          } else {
            const val = $gradField.value;
            const inputDate = new Date(
              utils.enUsDateFormatter.format(new Date(val))
            );
            if (inputDate < twoYearsAgoDate) {
              showConditionalField();
            } else {
              hideConditionalField();
            }
          }
        }, 500)
      );
    }
  };

  // executes code very specific to the "start" form
  const handleStartForm = () => {
    handleSpecialityField();
  };

  const init = () => {
    const $risingStarPage = document.getElementById('rising-star');
    const $startPage = document.getElementById('start');

    if ($risingStarPage) {
      handleRisingStarForm();
    } else if ($startPage) {
      handleStartForm();
    }

    // making sure that when the footer is in view, a class will be added, which will be used to hide ISI
    utils.handleElementVisibility(
      'footer.experiencefragment',
      'footer-in-view'
    );
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
