const utils = {
  handleElementVisibility: (selector: string, toggleSelector: string) => {
    const $element = document.querySelector(selector) as HTMLElement;
    const $body = document.body;

    const observer = new IntersectionObserver(e => {
      $body?.classList.toggle(toggleSelector, e[0].isIntersecting);
    });

    if ($element) {
      observer.observe($element);
    }
  },

  // basic US Date formatter
  enUsDateFormatter: new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }),

  debounce: (func: Function, wait: number = 300) => {
    let timer: ReturnType<typeof setTimeout>;
    return function (this: any, ...args: any[]) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), wait);
    };
  },

  // to check valid date. 02/31/2025 does not throw an error when passed in to new Date();
  // this makes sure that it is not a case.
  isValidDate: inputDate => {
    try {
      const [month, day, year] = inputDate.split('/').map(Number);
      const date = new Date(year, month - 1, day);

      return (
        date.getMonth() === month - 1 &&
        date.getDate() === day &&
        date.getFullYear() === year
      );
    } catch (e) {
      console.warn(e);
      return false;
    }
  },

  // check if date is future date for a given date string
  isFutureDate: function (inputDate) {
    try {
      let dateToCheck = new Date(inputDate);
      dateToCheck = new Date(this.enUsDateFormatter.format(dateToCheck));

      let currentDate = new Date();
      currentDate = new Date(this.enUsDateFormatter.format(currentDate));
      return dateToCheck > currentDate;
    } catch (e) {
      console.warn(e);
      return false;
    }
  },

  // returns a date that is years ago
  getDateFromYearsAgo: function (noOfYears = 0) {
    let currentDate = new Date();
    currentDate = new Date(this.enUsDateFormatter.format(currentDate));

    let yeasAgoDate = new Date(currentDate);
    yeasAgoDate.setFullYear(currentDate.getFullYear() - noOfYears);
    return yeasAgoDate;
  },

  // adds/removes a class called js-hide-field to the wrapper of the given element, which excludes from stopping form submission when it is not required.
  // And also add/removes a class called u-hide, which hides the element's wrapper from the DOM
  toggleFieldRequired: ($field, isRequired) => {
    const $wrapper =
      $field?.closest('.emu-form-text') ||
      $field?.closest('.emu-form-dropdown') ||
      $field?.closest('.emu-form-radio') ||
      $field?.closest('.emu-form-checkbox') ||
      $field?.closest('.emu-form-textarea');
    if (isRequired) {
      $wrapper?.classList.remove('js-hide-field', 'u-hide');
    } else {
      $wrapper?.classList.add('js-hide-field', 'u-hide');
    }
  },

  // when select element is changed,
  // if the value of the select element is equal to the "val" param,
  //    update the visibility of $target
  //    make it required if "isOtherFieldRequired" is true
  hideElOnSelect: function (
    $select: HTMLSelectElement,
    val: string,
    $target: HTMLElement,
    isOtherFieldRequired: Boolean
  ) {
    const $otherFieldwrapper = $target?.closest(
      '.emu-form-dropdown'
    ) as HTMLElement;
    $select?.addEventListener('change', () => {
      if (isOtherFieldRequired) {
        this.toggleFieldRequired($target, $select.value === val);
      } else {
        $otherFieldwrapper?.classList.toggle('u-hide', $select.value !== val);
      }
    });
  },

  // Removes/adds class names and sets "data-invalid" attributes, shows/hides error messages for a given input field element
  toggleErrorOnFormField: ($field, show = true) => {
    const $wrapper = $field?.closest('.emu-form-text');
    if ($wrapper) {
      const $errorText = $wrapper?.querySelector('.emu-form-text__error-text');
      if (show) {
        $wrapper.classList.add('js-has-error');
        $wrapper.setAttribute('data-invalid', 'true');
        $errorText.classList.add('js-show');
      } else {
        $wrapper.classList.remove('js-has-error');
        $wrapper.setAttribute('data-invalid', 'false');
        $errorText.classList.remove('js-show');
      }
    }
  },
};

export default utils;
